
/* eslint-disable @typescript-eslint/camelcase */
import { formatPrice } from "@/app/infrastructures/misc/Utils";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
import { prop, Vue } from "vue-class-component";

class Props {
  data = prop<any>({
    default: [],
  });
  startDate = prop<string>({
    default: "",
  });
  endDate = prop<string>({
    default: "",
  });
}
export default class ModalDetailCashback extends Vue.with(Props) {
  get showModalDetailCashback() {
    return BalanceController.showModalDetailCashback;
  }
  setShowModalDetailCashback(val: boolean) {
    BalanceController.setShowModalDetailCashback(val);
  }

  formatPriceMin(val: number) {
    return formatPrice(val);
  }

  formatPriceMax(val: number) {
    const resultMaxBalance = Math.abs(this.data[val].max_balance).toLocaleString("id");
    const resultMinBalance = Math.abs(this.data[val].min_balance).toLocaleString("id");
    const isLastIndex = this.data.length -1 === val
    if (isLastIndex) {
      return this.data[val].max_balance > 0 ? `Rp<${resultMaxBalance}` : `Rp>${resultMinBalance}`;
    }
    return `Rp<${resultMaxBalance}`;
  }
}
